import React from 'react';
import { Link } from 'gatsby';

import { PostMeta } from '../components/Elements';
import { timeToRead } from '../utils';

const PostMetaBlock = ({ wordCount, author, meta }) => {
  return (
    <PostMeta>
      <p>
        {author ? (
          <span>
            Written by <Link to={`/author/${author.slug}/`}>{author.name}</Link>
          </span>
        ) : null}
        {meta ? <span>{meta.createdAt}</span> : null}

        <span>
          {!meta ? (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V9C7.25 9.41421 7.58579 9.75 8 9.75H12C12.4142 9.75 12.75 9.41421 12.75 9C12.75 8.58579 12.4142 8.25 12 8.25H8.75V4Z"
                fill="#A8A8A8"
              />
            </svg>
          ) : null}
          {timeToRead(wordCount)} min read
        </span>
      </p>
      {meta ? <p>Last updated: {meta.updatedAt}</p> : null}
    </PostMeta>
  );
};

export default PostMetaBlock;
